import useHydrated from '~/hooks/use-hydrated'

const ClientOnly = ({ children, fallback }) => (useHydrated() ? <>{children}</> : <>{fallback}</>)

ClientOnly.defaultProps = {
  fallback: null,
  children: null,
}
ClientOnly.propTypes = {}

export default ClientOnly
